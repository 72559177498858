<template>
    <div>
        <h1>Free STL Viewer</h1>
        <div id="app"></div>
    </div>
</template>

<script>
// importing bootstrap plugins
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/tooltip';
// import 'bootstrap/js/dist/carousel';

export default {
    mounted: function() {
        (function(callback) {
            var s = document.createElement('script');
            s.setAttribute('src', "https://www.dualbox.com/loadapp.js");
            s.onload=callback; document.body.appendChild(s);
        })(function() {
            // load our app
            DualBox.load({
                "app"  : "3dprint-hq-viewer",
                "phase": "production", /* dev, alpha, beta or production */
                "loaderDiv": $('#app'),
            }, function() {
                // now instanciate it
                var app = DualBox.start({
                    "logLevel": "warn",
                    "profiler": false,
                    "div" : $('#app')
                });

                // Trigger the start sequence specific to our app
                app.start();
            });
        });
    }
};
</script>

<style>
#app {
    position: absolute;
    top: 66px;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: grey;
}

.page {
    background-color: #303030;
}
</style>
